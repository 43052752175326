import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  Paper,
  Avatar,
  AppBar,
  Toolbar,
  RadioGroup,
  Radio,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getGradeUrl } from "../../../../services/config";

const initialValues = {
  name: "",
  gender: "male",
  academicYear: "2022-23",
  dob: "",
};

const digitsOnly = (value) => /^\d+$/.test(value);

const phoneNumLength = (value) => {
  if (!!value) {
    return value.length === 10;
  }
  return true;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

function ChildForm({ handleNext }) {
  const [childCount, setChildCount] = useState(1);
  const [childDetails, setChildDetails] = useState([
    {
      name: "",
      nameError: false,
      nameErrorMsg: "",
      gender: "male",
      dob: "",
      dobError: false,
      dobErrorMsg: "",
      academicYear: "",
      grade: "",
      gradeError: false,
      gradeErrorMsg: "",
      child_previous_school: "",
      previous_board: "",
    },
  ]);
  const [value, setValue] = useState(null);
  const [grade, setGrade] = useState(1);
  const [gradeList, setGradeList] = useState([]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      localStorage.setItem("childData", JSON.stringify(values));
      handleNext();
    },
    validationSchema,
  });

  useEffect(() => {
    axios
      .get(`${getGradeUrl()}`)
      .then((res) => {
        if (res.status === 200) {
          setGradeList(res.data);
          setGrade(res.data[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const setChildDetailsHandler = (index, value, inputType) => {
    const newChildDetails = [...childDetails];

    if (inputType === "name") {
      if (value.match(/^[a-zA-Z]{3,}$/)) {
        newChildDetails[index].nameError = false;
        newChildDetails[index].nameErrorMsg = "";
      } else {
        newChildDetails[index].nameError = true;
        newChildDetails[index].nameErrorMsg =
          "Name should be atleast 3 characters";
      }
      newChildDetails[index].name = value;
      return;
    }
  };

  return (
    <>
      {/* render the form childCount times */}
      <FormikProvider value={formik} validateOnChange>
        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
          {/* for multiple children form */}
          {/* {childDetails.map((child, index) => {
        return (
          <Card>
            <CardContent>
              <Grid spacing={3} container>
                <Grid item xs={12}>
                  <Typography variant="h6">Child {index + 1}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    name={`name`}
                    value={child.name}
                    error={child.nameError}
                    helperText={child.nameErrorMsg}
                    onChange={(e) => {
                      setChildDetails(index, e.target.value, "name");
                    }}
                    // {...formik.getFieldProps("name")}
                    // error={Boolean(touched.name && errors.name)}
                    // helperText={touched.name && errors.name}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })} */}
          {[...Array(childCount).keys()].map((e, i) => (
            <Paper key={i} elevation={3} sx={{ p: "1em", my: "1em" }}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">Child {i + 1}</Typography>
                  {childCount > 1 && (
                    <Button
                      onClick={(e) => {
                        setChildCount((prev) => prev - 1);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  )}
                </Box>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  name={`name${i}`}
                  {...formik.getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    name="gender"
                    row
                    {...formik.getFieldProps("gender")}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Male </Typography>
                          <Iconify
                            sx={{
                              color: "#268EF2",
                              fontSize: 32,
                              mx: "0.2em",
                            }}
                            icon={"emojione-monotone:boy"}
                            inline={true}
                          />
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Female </Typography>
                          <Iconify
                            sx={{
                              color: "#268EF2",
                              fontSize: 32,
                              mx: "0.2em",
                            }}
                            icon={"emojione-monotone:girl"}
                            inline={true}
                          />
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="DOB"
                    disableFuture
                    value={value}
                    onChange={setValue}
                    renderInput={(params) => <TextField {...params} />}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>

                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Academic Year
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="2022-23"
                    name="academicYear"
                    row
                    {...formik.getFieldProps("academicYear")}
                    error={Boolean(touched.academicYear && errors.academicYear)}
                    helperText={touched.academicYear && errors.academicYear}
                  >
                    <FormControlLabel
                      value="2022-23"
                      control={<Radio />}
                      label="2022-23"
                    />
                    <FormControlLabel
                      value="2023-24"
                      control={<Radio />}
                      label="2023-24"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel id="grade">Grade</InputLabel>
                  <Select
                    labelId="grade"
                    id="grade"
                    name="grade"
                    label="grade"
                    {...getFieldProps("grade")}
                    error={Boolean(touched.grade && errors.grade)}
                    helperText={touched.grade && errors.grade}
                  >
                    {gradeList.map((item, index) => (
                      <MenuItem value={item?.id} key={index}>
                        {item?.grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-multiline-static"
                  label="Previous School Name"
                  name="prev_school"
                />

                <FormControl fullWidth>
                  <InputLabel id="prev_board">Board</InputLabel>
                  <Select
                    labelId="prev_board"
                    id="prev_board"
                    name="prev_board"
                    label="Board"
                  >
                    <MenuItem value="cbse" key="cbse">
                      CBSE
                    </MenuItem>
                    <MenuItem value="icse" key="icse">
                      ICSE
                    </MenuItem>
                    <MenuItem value="IGCSE" key="IGCSE">
                      IGCSE
                    </MenuItem>
                    <MenuItem value="IB" key="IB">
                      IB
                    </MenuItem>
                    <MenuItem value="state_board" key="state_board">
                      State
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Paper>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              setChildCount((prev) => prev + 1);
            }}
            fullWidth
            my={2}
          >
            Add Child
          </Button>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button variant="contained" type="submit">
              Next
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}

export default ChildForm;
