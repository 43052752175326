import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  Box,
  Button,
  TextField,
  Stack,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import MyAppbar from "../components/MyAppbar";
import axios from "axios";

import * as Yup from "yup";
import { getBaseUrl, getErpByContactUrl } from "../services/config";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
}));

const isEmptyObject = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

const initialValues = {
  erp_id: "",
  name: "",
};

const validationSchema = Yup.object({
  erp_id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
});

export default function ERPVerification() {
  const [userDetail, setUserDetail] = useState([]);
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const contact = localStorage.getItem("contact");

    axios
      .get(`apiV1/erp-by-contact/?caller_number=${contact}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          if (Object.keys(res.data.result.user_details).length === 0) {
            setUserDetail([]);
          } else {
            setUserDetail(res.data.result.user_details);
          }
          if (!isEmptyObject(res.data.result.parent_details)) {
            localStorage.setItem(
              "parent_details",
              JSON.stringify(res.data.result.parent_details)
            );
          }
        } else {
          console.log("Something Went Wrong");
          console.log("Status Code: " + res.data.status_code);
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setUploading(true);

      localStorage.setItem("erp_id", values.erp_id);
      localStorage.setItem("student_name", values.name);

      navigate("/existingadmissionform");
    },
    validationSchema,
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <Page title="Student Detail">
      <Box sx={{ flexDirection: "column" }}>
        <RootStyle sx={{ flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
            <MyAppbar textOne="Student Detail" />
          </Box>
        </RootStyle>

        <RootStyle sx={{ flexDirection: "column" }}>
          <Container maxWidth="sm">
            <ContentStyle>
              <RootStyle sx={{ flexDirection: "column" }}>
                <Typography variant="h6">Student Detail</Typography>

                {userDetail.length === 0 ? null : (
                  <>
                    <Typography variant="p" pb={3}>
                      Choose the students erp number
                    </Typography>
                    <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
                      {userDetail.map((item, index) => (
                        <Card
                          key={index}
                          sx={{ padding: 2, cursor: "pointer" }}
                          onClick={() => {
                            localStorage.setItem("erp_id", item.erp_id);
                            navigate("/existingadmissionform");
                          }}
                        >
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar aria-label="recipe">
                                {item.name.slice(0, 1)}
                              </Avatar>
                            }
                            action={
                              <IconButton aria-label="settings">
                                {/* <MoreVertIcon /> */}
                              </IconButton>
                            }
                            title={item.name}
                            subheader={item.erp_id}
                          />
                        </Card>
                      ))}
                    </Box>
                    <Typography variant="caption" py={3} textAlign={"center"}>
                      - OR -
                    </Typography>
                  </>
                )}

                <Typography variant="p">
                  Please enter the name and erp number
                </Typography>

                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3} marginY={2}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Student's Name"
                        name="name"
                        {...formik.getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />

                      <TextField
                        fullWidth
                        type="text"
                        label="ERP"
                        name="erp_id"
                        {...formik.getFieldProps("erp_id")}
                        error={Boolean(touched.erp_id && errors.erp_id)}
                        helperText={touched.erp_id && errors.erp_id}
                      />

                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        margin={4}
                        disabled={uploading}
                      >
                        {uploading ? (
                          <CircularProgress size={14} />
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </Stack>
                  </Form>
                </FormikProvider>
              </RootStyle>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Box>
    </Page>
  );
}
