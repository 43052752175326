import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../components/Page";
import schoolImage from "../assets/logo.svg";

import axios from "axios";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

export default function Welcome() {
  const navigate = useNavigate();

  const [instituteName, setInstituteName] = useState("");
  const [instituteLocation, setInstituteLocation] = useState("");
  const [gateName, setGateName] = useState("");

  let { instituteId, gateId } = useParams();
  useEffect(() => {
    axios
      .get(`apiV1/gate-details/${gateId}/`)
      .then((res) => {
        setInstituteName(res.data.institute.name);
        setInstituteLocation(res.data.institute.location);
        setGateName(res.data.name);

        // save gate-id, institute-id & institute-name to local storage
        localStorage.setItem("gateId", gateId);
        localStorage.setItem("instituteId", instituteId);
        localStorage.setItem("instituteName", res.data.institute.name);
      })
      .catch((res) => {
        console.log(res);
      });
  }, [gateId, instituteId]);

  return (
    <Page title="Welcome">
      <RootStyle>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="sticky">
            <Toolbar
              style={{
                flexDirection: "row",
                alignSelf: "center",
                padding: "0.5em",
              }}
            >
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Welcome
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </RootStyle>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              component="img"
              sx={{
                height: 160,
                width: 160,
                mx: "auto",
              }}
              alt="school logo"
              src={schoolImage}
            />

            <Typography
              align="center"
              variant="h5"
              sx={{ color: "text.secondary", mt: -2 }}
            >
              {instituteName}
            </Typography>
            <Typography
              align="center"
              variant="h6"
              sx={{ color: "text.secondary" }}
            >
              {instituteLocation}
            </Typography>

            <Typography align="center" variant="subtitle2" sx={{ mt: 4 }}>
              Gate
            </Typography>
            <Box sx={{ mx: "auto" }}>
              <Typography
                sx={{
                  fontSize: 32,
                  color: "black",
                  backgroundColor: "#C8E3FD",
                  padding: "0.1em 1em",
                  borderRadius: "1em",
                }}
              >
                {gateName}
              </Typography>
            </Box>

            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", mt: 5 }}
            >
              Click on continue to
            </Typography>
            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", mb: 2 }}
            >
              proceed further
            </Typography>

            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                navigate("/visitortype");
              }}
            >
              Continue
            </LoadingButton>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
