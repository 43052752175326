import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";

const initialValues = {
  fathers_name: "",
  mothers_name: "",
  fathers_occ: "",
  mothers_occ: "",
  nationality: "",
  address: "",
  email: "",
};

const validationSchema = Yup.object({
  fathers_name: Yup.string().required("Fathers Name is required").nullable(),
  mothers_name: Yup.string().required("Mothers Name is required").nullable(),
  // fathers_occ: Yup.string().required("Name is required"),
  // mothers_occ: Yup.string().required("Name is required"),
  nationality: Yup.string().required("Nationality is required").nullable(),
  address: Yup.string().required("Address is required").nullable(),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required")
    .nullable(),
});

function ParentForm({ userData, handleNext, handleBack }) {
  useEffect(() => {
    console.log(userData);

    if (Object.keys(userData).length > 0) {
      if (!!userData?.lead_name) {
        formik.setFieldValue("fathers_name", userData.lead_name);
      }
      if (!!userData?.lead_occuption) {
        formik.setFieldValue("fathers_occ", userData.lead_occuption);
      }
      if (!!userData?.mother_name) {
        formik.setFieldValue("mothers_name", userData.mother_name);
      }
      if (!!userData?.other_detail?.mother_occuption) {
        formik.setFieldValue(
          "mothers_occ",
          userData.other_detail.mother_occuption
        );
      }
      if (!!userData?.other_detail?.nationality) {
        formik.setFieldValue("nationality", userData.other_detail.nationality);
      }
      if (!!userData?.lead_address) {
        formik.setFieldValue("address", userData.lead_address);
      }
      if (!!userData.lead_email_id) {
        formik.setFieldValue("email", userData.lead_email_id);
      }
    }
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      console.log(values);
      localStorage.setItem("parentData", JSON.stringify(values));
      handleNext();
    },
    validationSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik} validateOnChange>
      <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Paper elevation={3} sx={{ p: "1em", pt: 0 }}>
          <Stack spacing={3}>
            <Box></Box>
            <TextField
              fullWidth
              type="text"
              label="Father's Name"
              name="fathers_name"
              {...formik.getFieldProps("fathers_name")}
              error={Boolean(touched.fathers_name && errors.fathers_name)}
              helperText={touched.fathers_name && errors.fathers_name}
            />
            <FormControl fullWidth>
              <InputLabel id="fathers_occ">Father's Occupation</InputLabel>
              <Select
                labelId="fathers_occ"
                id="fathers_occ"
                name="fathers_occ"
                label="Father's Occupation"
                placeholder="Father's Occupation"
                {...getFieldProps("fathers_occ")}
                // error={Boolean(touched.fathers_occ && errors.fathers_occ)}
                // helperText={touched.fathers_occ && errors.fathers_occ}
              >
                {/* {occupationList.map((item, index) => (
                                <MenuItem value={item?.id} key={index}>
                                  {item?.grade}
                                </MenuItem>
                              ))} */}
                <MenuItem value="private_job" key="private_job">
                  Private Job
                </MenuItem>
                <MenuItem value="govt_job" key="govt_job">
                  Government Job
                </MenuItem>
                <MenuItem value="business" key="business">
                  Business
                </MenuItem>
                <MenuItem value="home_maker" key="home_maker">
                  Home maker
                </MenuItem>
                <MenuItem value="other" key="other">
                  Other
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="text"
              label="Mother's Name"
              name="mothers_name"
              {...formik.getFieldProps("mothers_name")}
              error={Boolean(touched.mothers_name && errors.mothers_name)}
              helperText={touched.mothers_name && errors.mothers_name}
            />
            <FormControl fullWidth>
              <InputLabel id="mothers_occ">Mother's Occupation</InputLabel>
              <Select
                labelId="mothers_occ"
                id="mothers_occ"
                name="mothers_occ"
                label="Mother's Occupation"
                placeholder="Mother's Occupation"
                {...getFieldProps("mothers_occ")}
                //   error={Boolean(touched.fathers_occ && errors.fathers_occ)}
                //   helperText={touched.fathers_occ && errors.fathers_occ}
              >
                {/* {occupationList.map((item, index) => (
                                <MenuItem value={item?.id} key={index}>
                                  {item?.grade}
                                </MenuItem>
                              ))} */}
                <MenuItem value="private_job" key="private_job">
                  Private Job
                </MenuItem>
                <MenuItem value="govt_job" key="govt_job">
                  Government Job
                </MenuItem>
                <MenuItem value="business" key="business">
                  Business
                </MenuItem>
                <MenuItem value="home_maker" key="home_maker">
                  Home maker
                </MenuItem>
                <MenuItem value="other" key="other">
                  Other
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="nationality">Nationality</InputLabel>
              <Select
                labelId="nationality"
                id="nationality"
                name="nationality"
                label="Nationality"
                {...getFieldProps("nationality")}
                error={Boolean(touched.nationality && errors.nationality)}
                helperText={touched.nationality && errors.nationality}
              >
                {/* {occupationList.map((item, index) => (
                                <MenuItem value={item?.id} key={index}>
                                  {item?.grade}
                                </MenuItem>
                              ))} */}
                <MenuItem value="Indian" key="indian">
                  Indian
                </MenuItem>
                <MenuItem value="other" key="other">
                  Other
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="text"
              id="outlined-multiline-static"
              label="Residential Address"
              name="address"
              multiline
              rows={2}
              {...getFieldProps("address")}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />

            <TextField
              fullWidth
              label="Email Id"
              id="email"
              name="email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
        </Paper>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            // disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            // onClick={handleNext}
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default ParentForm;
