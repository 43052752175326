import React, { useState, useEffect } from "react";

// @mui
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Stack,
  Snackbar,
  Alert as MuiAlert,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { getGradeUrl } from "../services/config";

// components
import Page from "../components/Page";
import cameraIcon from "../assets/camera-icon.png";
import MyAppbar from "../components/MyAppbar";

import Resizer from "react-image-file-resizer";
import Iconify from "../components/Iconify";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  name: "",
  child: "",
  idCardType: "aadhar",
  academicYear: "2022-23",
  grade: 1,
  email: "",
  selfie: "",
  idCard: "",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const supportedExtensions = ["jpeg", "jpg", "png", "pdf"];

export default function NewAdmissionForm() {
  const [latitude, setLatitute] = useState("");
  const [longitude, setLongitude] = useState("");
  const [grade, setGrade] = useState(1);
  const [gradeList, setGradeList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [errOpen, setErrOpen] = useState(false); // Toast
  const [errMsg, setErrMsg] = useState(""); // Toast

  const [idCardAlreadyExists, setIdCardAlreadyExists] = useState(false);

  const handleErrClick = () => {
    setErrOpen(true);
  };

  const handleErrClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Toast
    setErrOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (localStorage.getItem("otp") === null) {
      navigate("/phoneverification");
    }

    if (!!localStorage.getItem("user")) {
      if (!!JSON.parse(localStorage.getItem("user")).id_card_image) {
        setIdCardAlreadyExists(true);
      }
    }

    axios
      .get(getGradeUrl())
      .then((res) => {
        if (res.status === 200) {
          setGradeList(res.data);
          setGrade(res.data[0].id);
        }
      })
      .catch((res) => {
        console.log(res);
      });

    if (!!user) {
      if (!!user.name) {
        formik.setFieldValue("name", user.name);
      }
      if (!!user.child_name) {
        formik.setFieldValue("child", user.child_name);
      }
      if (!!user.id_type) {
        formik.setFieldValue("idCardType", "aadhar");
      }
      if (!!user.email) {
        formik.setFieldValue("email", user.email);
      }
      if (!!user.grade) {
        formik.setFieldValue("grade", user.grade);
      }
    }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    function showPosition(position) {
      setLatitute(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    getLocation();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    child: Yup.string().required("Required"),
    academicYear: Yup.string().required("Required"),
    grade: Yup.string().required("Required"),
    // idCardType: Yup.string().required("Required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    selfie: Yup.mixed().required("Selfie is required"),
    idCard: !idCardAlreadyExists
      ? Yup.string().required("ID Card is required")
      : Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setUploading(true);
      const contact = parseInt(localStorage.getItem("contact"));
      const otp = localStorage.getItem("otp");
      const gateId = localStorage.getItem("gateId");
      const instituteId = localStorage.getItem("instituteId");
      const visitorType = localStorage.getItem("visitorType");

      const bodyFormData = new FormData();
      bodyFormData.append("visitor_details", contact);
      bodyFormData.append("visitor_type", visitorType);
      bodyFormData.append("institute", instituteId);
      bodyFormData.append("entry_gate", gateId);
      bodyFormData.append("otp", otp);
      bodyFormData.append("entry_image", values.selfie, values.selfie?.name);
      bodyFormData.append("image", values.selfie, values.selfie?.name);
      bodyFormData.append("academic_year", values.academicYear);
      bodyFormData.append("name", values.name);
      bodyFormData.append("child_name", values.child);
      bodyFormData.append("email", values.email);
      bodyFormData.append("grade", values.grade);

      localStorage.setItem("academic_year", values.academicYear);

      !idCardAlreadyExists && bodyFormData.append("id_type", values.idCardType);

      !idCardAlreadyExists &&
        bodyFormData.append(
          "id_card_image",
          values.idCard,
          values.idCard?.name
        );

      bodyFormData.append("latitude", latitude);
      bodyFormData.append("longitude", longitude);

      let formObject = {};
      bodyFormData.forEach((value, key) => {
        formObject[key] = value;
      });

      let json = JSON.stringify(formObject);

      localStorage.setItem("formObject", json);

      axios
        .post(`apiV1/gate/add-entry/`, bodyFormData)
        .then((res) => {
          localStorage.setItem("entryDetail", JSON.stringify(res.data));
          navigate("/waittime");
        })
        .catch((err) => {
          handleErrClick(); // Toast
          setErrMsg(err.response.status + " " + err.response.statusText);

          // if (err.response.status == 400) {
          //   setErrMsg("Bad Request!");
          // } else if (err.response.status == 401) {
          //   setErrMsg("Bad Request!");
          // } else if (err.response.status == "") {
          // }

          console.log("Error: ", err);
          console.log(err.response.status);

          setUploading(false);
        });
    },
    //,
    validationSchema,
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Page title="New Admission Form">
      <Box sx={{ flexDirection: "column" }}>
        <RootStyle sx={{ flexDirection: "column" }}>
          <Snackbar
            open={errOpen}
            autoHideDuration={6000}
            onClose={handleErrClose}
          >
            <Alert
              onClose={handleErrClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errMsg}
            </Alert>
          </Snackbar>

          <MyAppbar
            textOne="New Admission Form"
            textTwo="Fill this form and request entry"
          />

          <Container maxWidth="sm">
            <ContentStyle>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3} margin={2}>
                    <Box>
                      <Typography
                        variant="h6"
                        mt={2}
                        sx={{ textAlign: "center" }}
                      >
                        Take a selfie
                      </Typography>
                      <input
                        accept="image/*"
                        id="selfieInput"
                        multiple
                        type="file"
                        onChange={async (e) => {
                          const file = e.currentTarget.files[0];
                          await resizeFile(file).then((image) => {
                            setFieldValue("selfie", image);
                          });
                        }}
                        style={{ display: "none" }}
                        capture="camera"
                        required
                      />
                      <label htmlFor="selfieInput">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: 84,
                              width: 84,
                              mx: "auto",
                              borderRadius: "0.5em",
                            }}
                            alt="camera icon"
                            src={
                              formik.values.selfie
                                ? URL.createObjectURL(formik.values.selfie)
                                : cameraIcon
                            }
                          />
                        </Box>
                      </label>
                      <Typography color="#ff4842">
                        {touched.selfie && errors.selfie ? errors.selfie : ""}
                      </Typography>

                      {formik.values.selfie && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              setFieldValue("selfie", null);
                            }}
                          >
                            <Iconify
                              sx={{
                                color: "#ef5350",
                                fontSize: 24,
                                mx: "0.2em",
                              }}
                              icon={"ooui:clear"}
                              inline={true}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    <FormControl fullWidth>
                      <InputLabel id="academicYear">Acadamic Year</InputLabel>
                      <Select
                        labelId="academicYear"
                        id="academicYear"
                        name="academicYear"
                        value={formik.values.academicYear}
                        label="academicYear"
                        onChange={formik.handleChange}
                      >
                        <MenuItem value={"2022-23"}>2022-23</MenuItem>
                        <MenuItem value={"2023-24"}>2023-24</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      type="text"
                      label="Name"
                      name="name"
                      {...formik.getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Child Name"
                      name="child"
                      {...formik.getFieldProps("child")}
                      error={Boolean(touched.child && errors.child)}
                      helperText={touched.child && errors.child}
                    />
                    <TextField
                      fullWidth
                      label="E-mail Address"
                      id="email"
                      name="email"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="grade">Grade</InputLabel>
                      <Select
                        labelId="grade"
                        id="grade"
                        name="grade"
                        label="grade"
                        {...getFieldProps("grade")}
                        error={Boolean(touched.grade && errors.grade)}
                        helperText={touched.grade && errors.grade}
                      >
                        {gradeList.map((item, index) => (
                          <MenuItem value={item?.id} key={index}>
                            {item?.grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {!idCardAlreadyExists && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="idCardType">ID Card Type</InputLabel>
                          <Select
                            labelId="idCardType"
                            id="idCardType"
                            name="idCardType"
                            label="ID Card Type"
                            {...getFieldProps("idCardType")}
                            error={Boolean(
                              touched.idCardType && errors.idCardType
                            )}
                            helperText={touched.idCardType && errors.idCardType}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {[
                              "aadhar",
                              "voter",
                              "pan",
                              "ration",
                              "driving",
                              "passport",
                              "other",
                            ].map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item}
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Box>
                          <input
                            accept="image/jpeg,image/png"
                            id="idCard-input"
                            multiple
                            type="file"
                            onChange={async (e) => {
                              const path = e.target.value.split(".");
                              const extension = `${path[path.length - 1]}`;

                              if (supportedExtensions.includes(extension)) {
                                const file = e.currentTarget.files[0];
                                await resizeFile(file).then((image) => {
                                  setFieldValue("idCard", image);
                                });
                              } else {
                                handleErrClick(); // Toast
                                setErrMsg(
                                  "Invalid file type, please upload a PDF or Image."
                                );
                                // reset value
                                e.target.value = "";
                              }
                            }}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="idCard-input">
                            <Button variant="outlined" component="span">
                              Upload ID Card
                            </Button>
                          </label>
                          <Typography color="#ff4842">
                            {touched.idCard && errors.idCard
                              ? errors.idCard
                              : ""}
                          </Typography>

                          <Typography id="idCardFileName">
                            {formik.values.idCard && formik.values.idCard.name}
                          </Typography>
                        </Box>
                      </>
                    )}

                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      margin={4}
                      disabled={uploading}
                    >
                      {uploading ? <CircularProgress size={14} /> : "Continue"}
                    </Button>
                  </Stack>
                </Form>
              </FormikProvider>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Box>
    </Page>
  );
}
