import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import QRCode from "react-qr-code";

import Page from "../components/Page";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import MyAppbar from "../components/MyAppbar";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

export default function WaitTime() {
  const [checkedIn, setCheckedIn] = useState(true);

  const [userDetails, setUserDetails] = useState({});

  const instituteId = localStorage.getItem("instituteId");
  const gateId = localStorage.getItem("gateId");
  const visitorType = localStorage.getItem("visitorType");
  const entryDetail = JSON.parse(localStorage.getItem("entryDetail"));
  const entryId = entryDetail?.data?.id;

  useEffect(() => {
    localStorage.removeItem("otp");

    const formName = JSON.parse(localStorage.getItem("formObject"))?.name;
    setUserDetails((prev) => {
      return { ...prev, name: formName };
    });

    const formContact = JSON.parse(
      localStorage.getItem("formObject")
    )?.visitor_details;

    setUserDetails((prev) => {
      return { ...prev, contact: formContact };
    });
    const email = JSON.parse(localStorage.getItem("formObject"))?.email;
    setUserDetails((prev) => {
      return { ...prev, email: email };
    });

    const erpNumber = JSON.parse(
      localStorage.getItem("formObject")
    )?.erp_number;
    setUserDetails((prev) => {
      return { ...prev, erpNumber: erpNumber };
    });
  }, []);

  const [data, setData] = useState({
    entry_id: entryId,
    check_out: !checkedIn,
    type: "webVisit",
    time: new Date().toString(),
  });

  const handleChange = (e, newValue) => {
    setCheckedIn(newValue);
    setData((prevState) => ({
      ...prevState,
      check_out: !newValue,
    }));
  };

  const cardStyle = {
    display: "block",
    width: "100%",
    marginTop: "1em",
    margin: "auto",
    transitionDuration: "0.3s",
  };

  const navigate = useNavigate();

  return (
    <Page title="Wait Time">
      <RootStyle>
        <MyAppbar textOne="Visitor Pass" />
      </RootStyle>
      <RootStyle>
        <Container maxWidth="sm">
          <Box py={2} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <ToggleButtonGroup
              color="primary"
              value={checkedIn}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value={true}>Check-In</ToggleButton>
              <ToggleButton value={false}>Check-Out</ToggleButton>
            </ToggleButtonGroup>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                navigate(`/${instituteId}/${gateId}`);
              }}
            >
              Home
            </LoadingButton>
          </Box>

          <ContentStyle>
            <Box>
              <Typography
                align="center"
                variant="h6"
                component="h6"
                sx={{ flexGrow: 1, width: "100%", mx: "auto", mb: 1 }}
              >
                {checkedIn ? "Check-In" : "Check-Out"} QR Code
              </Typography>
              <Box
                m="auto"
                sx={{ mx: "auto" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <QRCode value={JSON.stringify(data)} size={200} />
              </Box>
            </Box>

            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", my: 2 }}
            >
              Please use QR code at entry gates.
            </Typography>

            <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
              }}
              style={cardStyle}
            >
              <CardContent
                sx={{
                  p: 1,
                  "&:last-child": { pb: 0 },
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{ mb: 0.5 }}
                  color="text"
                  variant="h6"
                  component="div"
                >
                  Visitor Details
                </Typography>
              </CardContent>

              {userDetails.name && (
                <CardContent sx={{ p: 1, "&:last-child": { pb: 0 } }}>
                  <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Name: {userDetails.name}
                  </Typography>
                </CardContent>
              )}

              {userDetails.erpNumber && (
                <CardContent sx={{ p: 1, "&:last-child": { pb: 0 } }}>
                  <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    ERP Number: {userDetails.erpNumber}
                  </Typography>
                </CardContent>
              )}
              {userDetails.contact && (
                <CardContent sx={{ p: 1, "&:last-child": { pb: 0 } }}>
                  <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Contact: {userDetails.contact}
                  </Typography>
                </CardContent>
              )}
              {userDetails.email && (
                <CardContent sx={{ p: 1, "&:last-child": { pb: 0 } }}>
                  <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Email: {userDetails.email}
                  </Typography>
                </CardContent>
              )}
            </Card>

            {visitorType === "newAdmission" && (
              <Box
                sx={{
                  mx: "auto",
                  p: 2,
                }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    if (!!instituteId) {
                      navigate(`/enquiryform?id=${instituteId}`);
                    } else {
                      navigate(`/enquiryform`);
                    }
                  }}
                  sx={{ px: 2 }}
                >
                  Enquiry Form
                </LoadingButton>
              </Box>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
