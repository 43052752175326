import React, { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Stack,
  Snackbar,
  Alert as MuiAlert,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";

// components
import Page from "../components/Page";
import cameraIcon from "../assets/camera-icon.png";
import MyAppbar from "../components/MyAppbar";

import Resizer from "react-image-file-resizer";
import Iconify from "../components/Iconify";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const reasonToVisitList = [
  { label: "Picking up the child", value: "Picking up the child" },
  { label: "General visit", value: "General visit" },
  { label: "Other", value: "Other" },
];

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  name: "",
  idCardType: "aadhar",
  selfie: "",
  idCard: "",
  reasonToVisit: reasonToVisitList[0].value,
  concern: "",
};

const isEmptyObject = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const supportedExtensions = ["jpeg", "jpg", "png", "pdf"];

export default function ExistingAdmissionForm() {
  const [latitude, setLatitute] = useState("");
  const [longitude, setLongitude] = useState("");
  const [errOpen, setErrOpen] = useState(false); // Toast
  const [errMsg, setErrMsg] = useState(""); // Toast

  const [idCardAlreadyExists, setIdCardAlreadyExists] = useState(false);

  const handleErrClick = () => {
    setErrOpen(true);
  };

  const handleErrClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Toast
    setErrOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("otp") === null) {
      navigate("/phoneverification");
    }

    if (!!localStorage.getItem("user")) {
      if (!!JSON.parse(localStorage.getItem("user")).id_card_image) {
        setIdCardAlreadyExists(true);
      }
    }

    let user = {};
    let parent_details = {};

    if (!!localStorage.getItem("user")) {
      user = JSON.parse(localStorage.getItem("user"));
    }
    if (!!localStorage.getItem("parent_details")) {
      parent_details = JSON.parse(localStorage.getItem("parent_details"));
    }

    if (!isEmptyObject(parent_details)) {
      if (!!parent_details?.father_name) {
        formik.setFieldValue("name", parent_details?.father_name);
      } else if (!!parent_details?.mother_name) {
        formik.setFieldValue("name", parent_details?.mother_name);
      } else if (!!parent_details?.guardian_name) {
        formik.setFieldValue("name", parent_details?.guardian_name);
      } else {
        if (!!user) {
          if (!!user.name) {
            formik.setFieldValue("name", user.name);
          }
        }
      }
    } else {
      if (!!user) {
        if (!!user.name) {
          formik.setFieldValue("name", user.name);
        }
      }
    }

    // if (!!user.id_type) {
    //   formik.setFieldValue("idCardType", "aadhar");
    // }
    // if (!!user.email) {
    //   formik.setFieldValue("email", user.email);
    // }
    // if (!!user.grade) {
    //   formik.setFieldValue("grade", user.grade);
    // }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    function showPosition(position) {
      setLatitute(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    getLocation();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    reasonToVisit: Yup.string().required("Required"),
    selfie: Yup.mixed().required("Selfie is required"),

    idCard: !idCardAlreadyExists
      ? Yup.string().required("ID Card is required")
      : Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const contact = localStorage.getItem("contact");
      const otp = localStorage.getItem("otp");
      const gateId = localStorage.getItem("gateId");
      const instituteId = localStorage.getItem("instituteId");
      const visitorType = localStorage.getItem("visitorType");
      const erp_id = localStorage.getItem("erp_id");
      const student_name = localStorage.getItem("student_name");

      const bodyFormData = new FormData();
      bodyFormData.append("visitor_details", contact);
      bodyFormData.append("visitor_type", visitorType);
      bodyFormData.append("institute", instituteId);
      bodyFormData.append("entry_gate", gateId);
      bodyFormData.append("otp", otp);
      bodyFormData.append("erp_id", erp_id);
      bodyFormData.append("entry_image", values.selfie, values.selfie?.name);
      bodyFormData.append("image", values.selfie, values.selfie?.name);
      bodyFormData.append("name", values.name);

      !idCardAlreadyExists && bodyFormData.append("id_type", values.idCardType);

      !idCardAlreadyExists &&
        bodyFormData.append(
          "id_card_image",
          values.idCard,
          values.idCard?.name
        );

      bodyFormData.append("reason", values.reasonToVisit);
      bodyFormData.append("concern", values.concern);
      bodyFormData.append("latitude", latitude);
      bodyFormData.append("longitude", longitude);

      let formObject = {};
      bodyFormData.forEach((value, key) => {
        formObject[key] = value;
      });

      let json = JSON.stringify(formObject);

      localStorage.setItem("formObject", json);

      axios
        .post(`apiV1/gate/add-entry/`, bodyFormData)
        .then((res) => {
          localStorage.setItem("entryDetail", JSON.stringify(res.data));
          navigate("/waittime");
        })
        .catch((err) => {
          handleErrClick(); // Toast
          setErrMsg(err.response.status + " " + err.response.statusText);
          isSubmitting = false;
        })
        .finally(() => {
          setSubmitting(false);
        });
    },

    validationSchema,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <Page title="Existing Admission Form">
      <Box sx={{ flexDirection: "column" }}>
        <RootStyle sx={{ flexDirection: "column" }}>
          <Snackbar
            open={errOpen}
            autoHideDuration={6000}
            onClose={handleErrClose}
          >
            <Alert
              onClose={handleErrClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errMsg}
            </Alert>
          </Snackbar>
          <MyAppbar
            textOne="Existing Admission Form"
            textTwo="Fill this form and request entry"
          />

          <Container maxWidth="sm">
            <ContentStyle>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3} marginY={2}>
                    <Box>
                      <Typography
                        variant="h6"
                        mt={2}
                        sx={{ textAlign: "center" }}
                      >
                        Take a selfie
                      </Typography>
                      <input
                        accept="image/*"
                        id="selfieInput"
                        multiple
                        type="file"
                        onChange={async (e) => {
                          const file = e.currentTarget.files[0];
                          await resizeFile(file).then((image) => {
                            setFieldValue("selfie", image);
                          });
                        }}
                        style={{ display: "none" }}
                        capture="camera"
                        required
                      />
                      <label htmlFor="selfieInput">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: 84,
                              width: 84,
                              mx: "auto",
                              borderRadius: "0.5em",
                            }}
                            alt="camera icon"
                            src={
                              formik.values.selfie
                                ? URL.createObjectURL(formik.values.selfie)
                                : cameraIcon
                            }
                          />
                        </Box>
                      </label>
                      <Typography color="#ff4842">
                        {touched.selfie && errors.selfie ? errors.selfie : ""}
                      </Typography>

                      {formik.values.selfie && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              setFieldValue("selfie", null);
                            }}
                          >
                            <Iconify
                              sx={{
                                color: "#ef5350",
                                fontSize: 24,
                                mx: "0.2em",
                              }}
                              icon={"ooui:clear"}
                              inline={true}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    <TextField
                      fullWidth
                      type="text"
                      label="Name"
                      name="name"
                      {...formik.getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />

                    {!idCardAlreadyExists && (
                      <>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="idCardType">
                              ID Card Type
                            </InputLabel>
                            <Select
                              labelId="idCardType"
                              label="ID Card Type"
                              id="idCardType"
                              name="idCardType"
                              {...getFieldProps("idCardType")}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {[
                                "aadhar",
                                "voter",
                                "pan",
                                "ration",
                                "driving",
                                "passport",
                                "other",
                              ].map((item, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={item}
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <input
                            accept="image/jpeg,image/png"
                            id="idCard-input"
                            multiple
                            type="file"
                            onChange={async (e) => {
                              const path = e.target.value.split(".");
                              const extension = `${path[path.length - 1]}`;

                              if (supportedExtensions.includes(extension)) {
                                const file = e.currentTarget.files[0];
                                await resizeFile(file).then((image) => {
                                  setFieldValue("idCard", image);
                                });
                              } else {
                                handleErrClick(); // Toast
                                setErrMsg(
                                  "Invalid file type, please upload a PDF or Image."
                                );
                                // reset value
                                e.target.value = "";
                              }
                            }}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="idCard-input">
                            <Button variant="outlined" component="span">
                              Upload ID Card
                            </Button>
                          </label>
                          <Typography color="#ff4842">
                            {touched.idCard && errors.idCard
                              ? errors.idCard
                              : ""}
                          </Typography>

                          <Typography id="idCardFileName">
                            {formik.values.idCard && formik.values.idCard.name}
                          </Typography>
                        </Box>
                      </>
                    )}

                    <FormControl component="fieldset">
                      <FormLabel component="legend">Reason of visit</FormLabel>
                      <RadioGroup
                        name="reasonToVisit"
                        {...formik.getFieldProps("reasonToVisit")}
                        onChange={(event) => {
                          setFieldValue(
                            "reasonToVisit",
                            event.currentTarget.value
                          );
                        }}
                      >
                        {reasonToVisitList.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            value={item.value}
                            control={<Radio />}
                            label={item.value}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <TextField
                      fullWidth
                      type="text"
                      id="outlined-multiline-static"
                      label="Concern (optional)"
                      name="concern"
                      multiline
                      rows={2}
                      {...formik.getFieldProps("concern")}
                      error={Boolean(touched.concern && errors.concern)}
                      helperText={touched.concern && errors.concern}
                    />

                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      margin={4}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={14} />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Stack>
                </Form>
              </FormikProvider>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Box>
    </Page>
  );
}
