import { Navigate, useRoutes } from "react-router-dom";
// layouts
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import NotFound from "./pages/Page404";
import Welcome from "./pages/Welcome";
import VisitorType from "./pages/VisitorType";
import EmployeeForm from "./pages/EmployeeForm";
import PhoneVerification from "./pages/PhoneVerification";
import OtpVerification from "./pages/OtpVerification";
import VendorForm from "./pages/VendorForm";
import WaitTime from "./pages/WaitTime";
import ERPVerification from "./pages/ERPVerification";
import EmployeeERP from "./pages/EmployeeERP";

import NewAdmissionForm from "./pages/NewAdmissionForm";
import ExistingAdmissionForm from "./pages/ExistingAdmissionForm";
import WelcomeHome from "./pages/WelcomeHome";
import EnquiryForm from "./pages/EnquiryForm/EnquiryForm";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <WelcomeHome /> },
        { path: "/:instituteId/:gateId", element: <Welcome /> },
        { path: "/visitortype", element: <VisitorType /> },
        { path: "/erpemployee", element: <EmployeeERP /> },
        { path: "/enquiryform", element: <EnquiryForm /> },
        { path: "/employeeform", element: <EmployeeForm /> },
        { path: "/phoneverification", element: <PhoneVerification /> },
        { path: "/otpverification", element: <OtpVerification /> },
        { path: "/erpverification", element: <ERPVerification /> },
        { path: "/vendorform", element: <VendorForm /> },
        { path: "/newadmissionform", element: <NewAdmissionForm /> },
        { path: "/existingadmissionform", element: <ExistingAdmissionForm /> },
        { path: "/waittime", element: <WaitTime /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);
}
