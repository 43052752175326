import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Iconify from "../../../../components/Iconify";
import { useEffect, useState } from "react";
import { getGradeUrl } from "../../../../services/config";

const NewChildFrom = ({ handleNext }) => {
  const [childDetails, setChildDetails] = useState([
    {
      id: "",
      name: "",
      nameError: false,
      nameErrorMsg: "",
      gender: "male",
      academicYear: "",
      dob: "",
      dobError: false,
      dobErrorMsg: "",
      grade: "",
      gradeError: false,
      gradeErrorMsg: "",
      prevSchool: "",
      board: "",
    },
  ]);
  const [gradeData, setGradeData] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchGradeData();
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!!userData?.new_child) {
      if (userData.new_child.length > 0) {
        populateChildData(userData?.new_child);
      }
    }
  }, []);

  const fetchGradeData = (parrams = {}) => {
    axios
      .get(getGradeUrl())
      .then((res) => {
        if (res.status === 200) {
          setGradeData(res.data);
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const populateChildData = (child_list) => {
    let tempChildDetails = [];
    child_list.forEach((child, index) => {
      let tempChildObj = {
        id: child?.id,
        name: child?.child_name,
        gender: child?.child_gender,
        dob: child?.Date_of_birth,
        grade: child?.child_class.id,
        prevSchool: child?.child_previous_school,
        board: child?.previous_board,
      };
      tempChildDetails.push(tempChildObj);
    });

    setChildDetails(tempChildDetails);
  };

  const storeChildData = (index, value, type) => {
    let tempChildDetails = [...childDetails];
    if (type === "name") {
      tempChildDetails[index].name = value;
    }
    if (type === "gender") {
      tempChildDetails[index].gender = value;
    }
    if (type === "dob") {
      tempChildDetails[index].dob = value;
    }
    if (type === "grade") {
      tempChildDetails[index].grade = value;
    }
    if (type === "prevSchool") {
      tempChildDetails[index].prevSchool = value;
    }
    if (type === "board") {
      tempChildDetails[index].board = value;
    }
    setChildDetails([...tempChildDetails]);
  };

  const validateEachField = (index, value, type) => {
    let tempChildDetails = [...childDetails];
    if (type === "name") {
      tempChildDetails[index].name = value;
      if (tempChildDetails[index].name.length === 0) {
        tempChildDetails[index].nameError = true;
        tempChildDetails[index].nameErrorMsg = "Name required";
      } else {
        tempChildDetails[index].nameError = false;
        tempChildDetails[index].nameErrorMsg = "";
      }
    }
    if (type === "gender") {
      tempChildDetails[index].gender = value;
    }
    if (type === "dob") {
      tempChildDetails[index].dob = value;
      if (tempChildDetails[index].dob === "") {
        tempChildDetails[index].dobError = true;
        tempChildDetails[index].dobErrorMsg = "Required";
      } else {
        tempChildDetails[index].dobError = false;
        tempChildDetails[index].dobErrorMsg = "";
      }
    }
    if (type === "grade") {
      tempChildDetails[index].grade = value;
      if (tempChildDetails[index].grade === "") {
        tempChildDetails[index].gradeError = true;
        tempChildDetails[index].gradeErrorMsg = "Required";
      } else {
        tempChildDetails[index].gradeError = false;
        tempChildDetails[index].gradeErrorMsg = "";
      }
    }
    if (type === "prevSchool") {
      tempChildDetails[index].prevSchool = value;
    }
    if (type === "board") {
      tempChildDetails[index].board = value;
    }
    setChildDetails([...tempChildDetails]);
  };

  const checkdata = () => {
    let errorOccur = false;
    let tempChildDetails = [...childDetails];
    tempChildDetails.map((each) => {
      if (each.name?.length === 0) {
        each.nameError = true;
        each.nameErrorMsg = "Name is required";
        errorOccur = true;
      }
      if (each.dob === "") {
        each.dobError = true;
        each.dobErrorMsg = "Required";
        errorOccur = true;
      }
      if (each.grade === "") {
        each.gradeError = true;
        each.gradeErrorMsg = "Required";
        errorOccur = true;
      }
    });
    setChildDetails([...tempChildDetails]);
    return errorOccur;
  };
  const addChild = () => {
    let tempChildDetails = [...childDetails];
    let tempChildObj = {
      id: "",
      name: "",
      nameError: false,
      nameErrorMsg: "",
      gender: "male",
      academicYear: "",
      dob: "",
      dobError: false,
      dobErrorMsg: "",
      grade: "",
      gradeError: false,
      gradeErrorMsg: "",
      prevSchool: "",
      board: "",
    };
    tempChildDetails.push(tempChildObj);
    setChildDetails([...tempChildDetails]);
  };
  const removeChild = (index) => {
    let tempChildDetails = [...childDetails];
    tempChildDetails.splice(index, 1);
    setChildDetails([...tempChildDetails]);
  };
  const submit = async () => {
    setUploading(true);
    let result = await checkdata();
    if (result) {
      console.log("error present", childDetails);
      setUploading(false);
    } else {
      console.log("no error", childDetails);
      localStorage.setItem("childDetails", JSON.stringify(childDetails));
      handleNext();
      setUploading(false);
    }
  };

  const formatDate = (date) => {
    let d = new Date();
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  const today = formatDate();

  return (
    <>
      {childDetails &&
        childDetails.map((eachChild, index) => {
          return (
            <Card sx={{ my: 2 }}>
              <CardContent>
                <Grid container spacing={3}>
                  {/* <Grid item xs={8}>
                    <Typography variant="h6">Child {index + 1}</Typography>
                  </Grid> */}
                  <Grid item xs={4}>
                    {childDetails.length > 1 && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => removeChild(index)}
                      >
                        Delete
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Name"
                      name="name"
                      value={eachChild.name}
                      error={eachChild.nameError}
                      helperText={eachChild.nameErrorMsg}
                      onChange={(e) =>
                        storeChildData(index, e.target.value, "name")
                      }
                      onBlur={(e) =>
                        validateEachField(index, e.target.value, "name")
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={eachChild.gender}
                        row
                        onChange={(e) =>
                          storeChildData(index, e.target.value, "gender")
                        }
                        onBlur={(e) =>
                          validateEachField(index, e.target.value, "gender")
                        }
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Male </Typography>
                              <Iconify
                                sx={{
                                  color: "#268EF2",
                                  fontSize: 32,
                                  mx: "0.2em",
                                }}
                                icon={"emojione-monotone:boy"}
                                inline={true}
                              />
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Female </Typography>
                              <Iconify
                                sx={{
                                  color: "#268EF2",
                                  fontSize: 32,
                                  mx: "0.2em",
                                }}
                                icon={"emojione-monotone:girl"}
                                inline={true}
                              />
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="DOB"
                      type="date"
                      value={eachChild.dob}
                      error={eachChild.dobError}
                      helperText={eachChild.dobErrorMsg}
                      onChange={(e) =>
                        storeChildData(index, e.target.value, "dob")
                      }
                      onBlur={(e) =>
                        validateEachField(index, e.target.value, "dob")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: today,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      required
                      error={eachChild.gradeError}
                    >
                      <InputLabel>Grade</InputLabel>
                      <Select
                        label="grade"
                        value={eachChild.grade}
                        error={eachChild.gradeError}
                        helperText={eachChild.gradeErrorMsg}
                        onChange={(e) =>
                          storeChildData(index, e.target.value, "grade")
                        }
                        onBlur={(e) =>
                          validateEachField(index, e.target.value, "grade")
                        }
                      >
                        {gradeData.map((item, index) => (
                          <MenuItem value={item?.id} key={index}>
                            {item?.grade}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{eachChild.gradeErrorMsg}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Previous School Name"
                      // name="prev_school"
                      value={eachChild.prevSchool}
                      onChange={(e) =>
                        storeChildData(index, e.target.value, "prevSchool")
                      }
                      onBlur={(e) =>
                        validateEachField(index, e.target.value, "prevSchool")
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="prev_board">Board</InputLabel>
                      <Select
                        label="Board"
                        value={eachChild.board}
                        onChange={(e) =>
                          storeChildData(index, e.target.value, "board")
                        }
                        onBlur={(e) =>
                          validateEachField(index, e.target.value, "board")
                        }
                      >
                        <MenuItem value="cbse" key="cbse">
                          CBSE
                        </MenuItem>
                        <MenuItem value="icse" key="icse">
                          ICSE
                        </MenuItem>
                        <MenuItem value="IGCSE" key="IGCSE">
                          IGCSE
                        </MenuItem>
                        <MenuItem value="IB" key="IB">
                          IB
                        </MenuItem>
                        <MenuItem value="state_board" key="state_board">
                          State
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      {/* <Button fullWidth variant="contained" onClick={() => addChild()}>
        Add Child
      </Button> */}
      {/* <Button fullWidth variant="contained" onClick={() => submit()}>
        Submit
      </Button> */}

      {/* <Button
        variant="outlined"
        onClick={() => {
          addChild();
        }}
        fullWidth
        my={2}
      >
        Add Child
      </Button> */}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />

        <Button
          variant="contained"
          type="submit"
          onClick={() => submit()}
          disabled={uploading}
        >
          {uploading ? <CircularProgress size={14} /> : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default NewChildFrom;
