const isDevMode = false;

export function getBaseUrl() {
  if (isDevMode) {
    return "https://myschoolgate.dev-k8.letseduvate.com/qbox/";
  } else {
    return "https://myschoolgate.in/qbox/";
  }
}
export function getGradeUrl() {
  if (isDevMode) {
    return "https://marketing.dev-k8.letseduvate.com/qbox/grade/";
  } else {
    return "https://marketing.letseduvate.com/qbox/grade/";
  }
}

export function getLeadDetailUrl() {
  if (isDevMode) {
    return "https://marketing.dev-k8.letseduvate.com/qbox/lead_info_filter_V2/";
  } else {
    return "https://marketing.letseduvate.com/qbox/lead_info_filter_V2/";
  }
}

export function getEnquiryPostUrl() {
  if (isDevMode) {
    return "https://marketing.dev-k8.letseduvate.com/qbox/UpdateLeadDetailsByMyGate/";
  } else {
    return "https://marketing.letseduvate.com/qbox/UpdateLeadDetailsByMyGate/";
  }
}
