import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  Paper,
  Avatar,
  AppBar,
  Toolbar,
  RadioGroup,
  Radio,
} from "@mui/material";
import { getEnquiryPostUrl } from "../../../../services/config";

import { useSearchParams } from "react-router-dom";

const initialValues = {
  speacialNeed: "yes",
  remarks: "",
};

const validationSchema = Yup.object({});

function StudentNeedsForm({
  agree,
  setAgree,
  userData,
  handleBack,
  handleNext,
}) {
  const [name, setName] = useState("");
  const [childData, setChildData] = useState([]);
  const [userType, setUserType] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      // Submit Form Now

      const childData = JSON.parse(localStorage.getItem("childDetails"));
      const parentData = JSON.parse(localStorage.getItem("parentData"));
      const userData = JSON.parse(localStorage.getItem("userData"));
      // const instituteId = JSON.parse(localStorage.getItem("institute"))?.id;

      let branchId = searchParams.get("id");

      const otherDetail = {
        mother_occuption: parentData?.mothers_occ,
        nationality: parentData?.nationality,
        is_child_need_special_education: values?.speacialNeed,
        child_need_special_education_remark: values?.remarks,
      };

      const childArray = [];
      const childFormDataObj = childData.forEach((child) => {
        childArray.push({
          id: child.id,
          child_name: child.name,
          child_gender: child.gender,
          Date_of_birth: child.dob,
          child_class: child.grade.toString(),
          child_previous_school: child.prevSchool,
          previous_board: child.board,
        });
      });

      const bodyFormObj = {
        id: null,
        academic_year: userData?.academic_year,
        branch: parseInt(branchId),
        lead_name: parentData?.fathers_name,
        mother_name: parentData?.mothers_name,
        lead_occuption: parentData?.fathers_occ,
        lead_contact_no: localStorage.getItem("contact"),
        lead_email_id: parentData?.email,
        lead_address: parentData?.address,
        other_detail: otherDetail,
        child_detail_fk: childArray,
      };

      // if (userData?.id) {
      //   bodyFormObj.id = userData?.id.toString();
      // }

      if (!!localStorage.getItem("academic_year")) {
        bodyFormObj.academic_year = localStorage.getItem("academic_year");
      }

      axios
        .post(`${getEnquiryPostUrl()}`, bodyFormObj, {
          headers: {
            "Access-Api-Key": "ntNPDkXA.KkRNanAflhbTBD4WowdaMMIE32dd5bveyH",
          },
        })
        .then((res) => {
          console.log("res");
          console.log(res);
          localStorage.removeItem("parentData");
          localStorage.removeItem("childDetails");
          localStorage.removeItem("userData");
          localStorage.removeItem("contact");
          handleNext();
        })
        .catch((err) => console.log(err));
    },
    validationSchema,
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  useEffect(() => {
    let childData = JSON.parse(localStorage.getItem("childDetails"));
    setChildData(childData);

    if (Object.keys(userData).length > 0) {
      if (!!userData?.other_detail?.is_child_need_special_education) {
        formik.setFieldValue(
          "speacialNeed",
          userData.other_detail.is_child_need_special_education
        );
      }
      if (!!userData?.other_detail?.child_need_special_education_remark) {
        formik.setFieldValue(
          "remarks",
          userData.other_detail.child_need_special_education_remark
        );
      }
    }
  }, []);

  useEffect(() => {
    let parentData = JSON.parse(localStorage.getItem("parentData"));
    if (userType == "Father") {
      setName(parentData?.fathers_name);
    } else if (userType == "Mother") {
      setName(parentData?.mothers_name);
    } else {
      setName(parentData?.fathers_name);
    }
  }, [userType]);

  return (
    <FormikProvider value={formik} validateOnChange>
      <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Paper elevation={3} sx={{ p: "1em", pt: 0 }}>
          <Stack spacing={3}>
            <Box></Box>
            <FormControl>
              <Typography variant="h6">
                Does your child has special education needs?
              </Typography>
              <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="speacialNeed"
                row
                {...formik.getFieldProps("speacialNeed")}
                // error={Boolean(touched.speacialNeed && errors.speacialNeed)}
                // helperText={touched.speacialNeed && errors.speacialNeed}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {
                <TextField
                  fullWidth
                  type="text"
                  id="remarks"
                  label="Remarks"
                  name="remarks"
                  {...formik.getFieldProps("remarks")}
                  error={Boolean(touched.remarks && errors.remarks)}
                  helperText={touched.remarks && errors.remarks}
                  multiline
                  rows={2}
                />
              }

              {/* Facilities Checkbox */}

              {/* <Typography variant="h6" mt="1em">
                Facilities you are looking for
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Computer labs"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Sports facility "
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Library and multimedia center "
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Medical facility"
                />
                <FormControlLabel control={<Checkbox />} label="School Mess" />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Transportation"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Educational Trips"
                />
              </FormGroup> */}

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setAgree(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="body" sx={{ margin: "1em" }}>
                      I, <b>{name}</b>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue="Father"
                        row
                        onChange={(e) => {
                          setUserType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="Father"
                          control={<Radio />}
                          label="Father"
                        />
                        <FormControlLabel
                          value="Mother"
                          control={<Radio />}
                          label="Mother"
                        />
                        {/* <FormControlLabel
                          value="Guardian"
                          control={<Radio />}
                          label="Guardian"
                        /> */}
                      </RadioGroup>
                      of{" "}
                      {childData.map((each, key) => {
                        return (
                          <b>
                            {each?.name}
                            {childData.length == key + 1 ? "" : ", "}
                          </b>
                        );
                      })}{" "}
                      agree to abide by rules and regulations. I would like them
                      to be considered for admission in academic year{" "}
                      {localStorage.getItem("academic_year")}.
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Stack>
        </Paper>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            variant="contained"
            type="submit"
            disabled={!agree || isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={14} /> : "Next"}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default StudentNeedsForm;
