import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";

// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Snackbar,
  Alert as MuiAlert,
  Button,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";

// components
import Page from "../components/Page";
import MyAppbar from "../components/MyAppbar";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  contact: "",
};

const digitsOnly = (value) => /^\d+$/.test(value);

const phoneNumLength = (value) => {
  if (!!value) {
    return value.length === 10;
  }
  return true;
};

const validationSchema = Yup.object({
  contact: Yup.string()
    .required("Phone number is required")
    .test("Digits only", "This field should have digits only", digitsOnly)
    .test("len", "Must be 10 digits long", phoneNumLength),
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EmployeeForm() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false); // Toast
  const [errOpen, setErrOpen] = useState(false); // Toast
  const [msg, setMsg] = useState(""); // Toast
  const [errMsg, setErrMsg] = useState(""); // Toast
  // Toast
  const handleClick = () => {
    setOpen(true);
  };
  // Toast
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleErrClick = () => {
    setErrOpen(true);
  };

  const handleErrClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Toast
    setErrOpen(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      localStorage.setItem("contact", values.contact);

      const visitorType = localStorage.getItem("visitorType");

      axios
        .get(`apiV1/gate/visitor-details/`, {
          params: {
            type: visitorType,
            contact: values.contact,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            handleClick(); // Toast

            setMsg("OTP Sent Successfully!");

            setTimeout(() => {
              navigate("/otpverification");
            }, 1000);
          } else {
            handleErrClick(); // Toast
            setErrMsg("Couldn't send OTP!");
          }
        })
        .catch((err) => {
          handleErrClick(); // Toast
          setErrMsg(err.response.status + " " + err.response.statusText);
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema,
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <Page title="Phone Verification form">
      <RootStyle sx={{ flexDirection: "column" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={errOpen}
          autoHideDuration={6000}
          onClose={handleErrClose}
        >
          <Alert
            onClose={handleErrClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errMsg}
          </Alert>
        </Snackbar>

        <MyAppbar textOne="Verification" />

        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ flexGrow: 1 }} py={2} px={2} fullWidth>
              <Typography variant="h6">Phone Verification</Typography>
              <Typography variant="p">
                Please enter your phone number to generate an otp so that we
                could verify you.
              </Typography>
            </Box>
            <FormikProvider value={formik} validateOnChange>
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} margin={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    id="contact"
                    name="contact"
                    {...getFieldProps("contact")}
                    error={Boolean(touched.contact && errors.contact)}
                    helperText={touched.contact && errors.contact}
                    inputProps={{ inputMode: "numeric" }}
                  />

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    margin={4}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={14} /> : "Continue"}
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
