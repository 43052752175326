import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material";

// components
import Page from "../components/Page";
import MyAppbar from "../components/MyAppbar";
import cameraIcon from "../assets/camera-icon.png";

import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  name: "",
  // erpNumber: "",
  selfie: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  // erpNumber: Yup.string().required("ERP number is required"),
  selfie: Yup.mixed().required("Selfie is required"),
});

export default function EmployeeForm() {
  const [latitude, setLatitute] = useState("");
  const [longitude, setLongitude] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      const gateId = localStorage.getItem("gateId");
      const instituteId = localStorage.getItem("instituteId");
      const visitorType = localStorage.getItem("visitorType");

      const bodyFormData = new FormData();
      bodyFormData.append("visitor_type", visitorType);
      bodyFormData.append("institute", instituteId);
      bodyFormData.append("entry_gate", gateId);
      bodyFormData.append("entry_image", values.selfie, values.selfie?.name);
      bodyFormData.append("image", values.selfie, values.selfie?.name);
      bodyFormData.append("name", values.name);
      bodyFormData.append("erp_id", localStorage.getItem("erp_id"));
      bodyFormData.append("latitude", latitude);
      bodyFormData.append("longitude", longitude);

      let formObject = {};
      bodyFormData.forEach((value, key) => {
        formObject[key] = value;
      });

      let json = JSON.stringify(formObject);

      localStorage.setItem("formObject", json);

      axios
        .post(`apiV1/gate/employee/`, bodyFormData)
        .then((res) => {
          localStorage.setItem("entryDetail", JSON.stringify(res.data));
          navigate("/waittime");
        })
        .catch((err) => {
          console.log("Error", err);

          actions.setSubmitting(false);
        });
    },
    validationSchema,
  });

  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    function showPosition(position) {
      setLatitute(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    getLocation();
  }, []);

  const erpId = localStorage.getItem("erp_id");

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <Page title="Employee Form">
      <RootStyle sx={{ flexDirection: "column" }}>
        <MyAppbar textOne="Employee Check-in" />

        <Container maxWidth="sm">
          <ContentStyle>
            <FormikProvider value={formik} validateOnChange>
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} margin={2}>
                  <Box>
                    <Typography
                      variant="h6"
                      mt={2}
                      sx={{ textAlign: "center" }}
                    >
                      Take a selfie
                    </Typography>
                    <input
                      accept="image/*"
                      id="selfieInput"
                      multiple
                      type="file"
                      onChange={async (e) => {
                        const file = e.currentTarget.files[0];
                        await resizeFile(file).then((image) => {
                          setFieldValue("selfie", image);
                        });
                      }}
                      style={{ display: "none" }}
                      capture="camera"
                      required
                    />
                    <label htmlFor="selfieInput">
                      <Box
                        component="img"
                        sx={{
                          height: 84,
                          width: 84,
                          mx: "auto",
                        }}
                        alt="camera icon"
                        src={cameraIcon}
                      />
                    </label>
                    <Typography color="#ff4842">
                      {touched.selfie && errors.selfie ? errors.selfie : ""}
                    </Typography>

                    <Typography id="selfieFileName">
                      {formik.values.selfie && formik.values.selfie.name}
                    </Typography>
                  </Box>

                  <Typography variant="h6" mt={2}>
                    ERP : {erpId}
                  </Typography>

                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    name="name"
                    {...formik.getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  {/* <TextField
                    fullWidth
                    label="ERP Number"
                    id="erpNumber"
                    name="erpNumber"
                    {...getFieldProps("erpNumber")}
                    error={Boolean(touched.erpNumber && errors.erpNumber)}
                    helperText={touched.erpNumber && errors.erpNumber}
                    value={localStorage.getItem("erp_id")}
                    disabled
                  /> */}

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    margin={4}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={14} /> : "Continue"}
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
