import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useTheme } from "@mui/material/styles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import Iconify from "../../components/Iconify";
// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  Paper,
  Avatar,
  AppBar,
  Toolbar,
} from "@mui/material";

// components
import Page from "../../components/Page";
import Logo from "../../assets/image2.png";
import SuccessImg from "../../assets/success.png";
import PhoneNumForm from "./subforms/phone_num";
import ChildForm from "./subforms/child";
import ParentForm from "./subforms/parent";
import StudentNeedsForm from "./subforms/student_needs";
import MyAppbar from "../../components/MyAppbar";
import NewChildFrom from "./subforms/new_child/indexnew";
import { getGradeUrl, getLeadDetailUrl } from "../../services/config";

const steps = ["Child", "Parents", "Student Needs"];

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  name1: "",
  // erpNumber: "",
  selfie: "",
  contact: "",
};

const digitsOnly = (value) => /^\d+$/.test(value);

const phoneNumLength = (value) => {
  if (!!value) {
    return value.length === 10;
  }
  return true;
};

const validationSchema = Yup.object({
  name1: Yup.string().required("Required"),
  // erpNumber: Yup.string().required("ERP number is required"),
  // selfie: Yup.mixed().required("Selfie is required"),
  contact: Yup.string()
    .required("Phone number is required")
    .test("Digits only", "This field should have digits only", digitsOnly)
    .test("len", "Must be 10 digits long", phoneNumLength),
});

export default function EnquiryForm() {
  const [latitude, setLatitute] = useState("");
  const [longitude, setLongitude] = useState("");
  const [value, setValue] = useState(null);
  const [childCount, setChildCount] = useState(1);

  const [activePage, setActivePage] = useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [grade, setGrade] = useState(1);
  const [gradeList, setGradeList] = useState([]);

  const [agree, setAgree] = useState(false);

  const [userData, setUserData] = useState({});

  const occupationList = ["a", "b", "c", "other"];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigate = useNavigate();
  const instituteId = localStorage.getItem("instituteId");

  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      const gateId = localStorage.getItem("gateId");
      const visitorType = localStorage.getItem("visitorType");

      const bodyFormData = new FormData();
      bodyFormData.append("visitor_type", visitorType);
      bodyFormData.append("institute", instituteId);
      bodyFormData.append("entry_gate", gateId);
      bodyFormData.append("image", values.selfie, values.selfie?.name);
      bodyFormData.append("name", values.name);
      bodyFormData.append("erp_number", localStorage.getItem("erp_id"));
      bodyFormData.append("latitude", latitude);
      bodyFormData.append("longitude", longitude);

      let formObject = {};
      bodyFormData.forEach((value, key) => {
        formObject[key] = value;
      });

      let json = JSON.stringify(formObject);

      localStorage.setItem("formObject", json);

      axios
        .post(`apiV1/gate/employee/`, bodyFormData)
        .then((res) => {
          localStorage.setItem("entryDetail", JSON.stringify(res.data));
          navigate("/waittime");
        })
        .catch((err) => {
          console.log(err);

          actions.setSubmitting(false);
        });
    },
    validationSchema,
  });

  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    function showPosition(position) {
      setLatitute(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    getLocation();

    axios
      .get(getGradeUrl())
      .then((res) => {
        if (res?.status === 200) {
          setGradeList(res.data);
          setGrade(res.data[0].id);
        } else {
          console.log("something went wrong:", res?.status);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

    // If user Phone Number is already saved in localStorage, then show the form directly and skip the phone number form

    // let lead_contact = localStorage.getItem("contact");
    // if (!!lead_contact) {
    //   setActivePage((prevActivePage) => prevActivePage + 1);
    //   axios
    //     .get(`${getLeadDetailUrl()}?mobile_no=${lead_contact}`, {
    //       headers: {
    //         "Access-Api-Key": "ntNPDkXA.KkRNanAflhbTBD4WowdaMMIE32dd5bveyH",
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data?.status_code === 409) {
    //         console.log("No data found");
    //       } else {
    //         setUserData(res.data[0]);
    //         localStorage.setItem("userData", JSON.stringify(res.data[0]));
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }, []);

  const erpId = localStorage.getItem("erp_id");

  const { values } = formik;

  return (
    <Page title="Enquiry Form">
      <RootStyle sx={{ flexDirection: "column" }}>
        <MyAppbar
          textOne="Enquiry Form"
          textTwo="School admission enquiry form"
        />

        <Container maxWidth="sm">
          <ContentStyle>
            {/* Mobile Number Form /// PAGE 0*/}
            {activePage === 0 && (
              <PhoneNumForm
                userData={userData}
                setUserData={setUserData}
                setActivePage={setActivePage}
              />
            )}

            {/* Stepper Form /// PAGE 1*/}

            {activePage === 1 && (
              <Stack spacing={3}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ width: "100%" }} py={2}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  {activeStep === 0 && (
                    // <ChildForm
                    //   childCount={childCount}
                    //   setChildCount={setChildCount}
                    //   handleNext={handleNext}
                    // />
                    <NewChildFrom handleNext={handleNext} />
                  )}

                  {activeStep === 1 && (
                    <ParentForm
                      handleNext={handleNext}
                      userData={userData}
                      handleBack={handleBack}
                    />
                  )}

                  {activeStep === 2 && (
                    <StudentNeedsForm
                      agree={agree}
                      setAgree={setAgree}
                      userData={userData}
                      handleBack={handleBack}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === steps.length ? (
                    <Box>
                      <Box
                        mx="auto"
                        pt={10}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={SuccessImg} height="80px" width="80px"></img>
                      </Box>
                      <Typography
                        sx={{ pt: 2 }}
                        variant="h6"
                        textAlign={"center"}
                      >
                        Thank you!
                      </Typography>
                      <Box
                        mx="auto"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{ pt: 1 }}
                          variant="subtitle"
                          textAlign={"center"}
                        >
                          Form Submitted Successfully
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Button
                          // fullWidth
                          size="large"
                          type="submit"
                          variant="outlined"
                          margin={4}
                          alignSelf="center"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          Fill another form
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box></Box>
              </Stack>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

const Topbar = ({ textOne, textTwo }) => {
  const theme = useTheme();
  return (
    <AppBar
      position="sticky"
      style={{
        flexDirection: "row",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Toolbar
        style={{
          flexDirection: "row",
          alignSelf: "center",
          padding: "0.5em",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 72,
            mx: "auto",
            my: 0,
            alignSelf: "flex-start",
          }}
          alt=" logo"
          src={Logo}
        />
        <Box style={{ marginLeft: "1em" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: theme.palette.text.primary }}
          >
            {textOne}
          </Typography>
          <Typography
            variant="h8"
            component="div"
            sx={{
              flexGrow: 1,
              alignSelf: "flex-end",
              color: theme.palette.text.secondary,
            }}
            color="common.white"
          >
            {textTwo}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
