import React, { useEffect } from "react";
import Logo from "../assets/logo2.png";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";

const MyAppbar = ({ textOne, textTwo }) => {
  const navigate = useNavigate();

  
  const instituteId = localStorage.getItem("instituteId");
  const gateId = localStorage.getItem("gateId");

  return (
    <AppBar
      position="sticky"
      style={{
        flexDirection: "row",
      }}
    >
      <Toolbar
        style={{
          flexDirection: "row",
          alignSelf: "center",
          padding: " 1em",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 60,
            width: 60,
            mx: "auto",
            alignSelf: "flex-start",
          }}
          alt=" logo"
          src={Logo}
          onClick={() => {
            if (instituteId && gateId) {
              navigate(`/${instituteId}/${gateId}`);
            } else {
              navigate("/");
            }
          }}
        />
        <Box style={{ marginLeft: "1em" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            color="common.white"
          >
            {textOne}
          </Typography>
          <Typography
            variant="h8"
            component="div"
            sx={{ flexGrow: 1, alignSelf: "flex-end" }}
            color="common.white"
          >
            {textTwo}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppbar;
