import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Avatar, Card, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Page from "../components/Page";
import Iconify from "../components/Iconify";
import MyAppbar from "../components/MyAppbar";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function VisitorType() {
  const navigate = useNavigate();

  const saveToLocalStorage = (visitorType) => {
    localStorage.setItem("visitorType", visitorType);

    switch (visitorType) {
      case "employee":
        navigate("/erpemployee");
        break;
      case "vendor":
        navigate("/phoneverification");
        break;
      case "newAdmission":
        navigate("/phoneverification");
        break;
      case "existingAdmission":
        navigate("/phoneverification");
        break;

      default:
        break;
    }
  };

  return (
    <Page title="Visitor Type">
      <RootStyle sx={{ flexDirection: "column" }}>
        <MyAppbar textOne="Visitor Type" />

        <Box sx={{ width: "100%", margin: "auto" }} maxWidth="sm">
          <Typography align="center" variant="h6" sx={{ my: 4 }}>
            Please select a category
          </Typography>
          <Grid
            sx={{ px: 2 }}
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 2, md: 2 }}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 8, sm: 8, md: 8 }}
          >
            {/* <Grid item xs={4}>
              <VisitorTypeCard
                name={"Employee"}
                icon={"mdi:card-account-details-outline"}
                onClick={() => {
                  saveToLocalStorage("employee");
                }}
              />
            </Grid> */}

            <Grid item xs={4}>
              <VisitorTypeCard
                name={"New"}
                name2={"Admission"}
                icon={"mdi:school-outline"}
                onClick={() => {
                  saveToLocalStorage("newAdmission");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VisitorTypeCard
                name={"Existing"}
                name2={"Admission"}
                icon={"mdi:account-school-outline"}
                onClick={() => {
                  saveToLocalStorage("existingAdmission");
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VisitorTypeCard
                name={"Vendor"}
                name2={" "}
                icon={"mdi:human-greeting"}
                onClick={() => {
                  saveToLocalStorage("vendor");
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </RootStyle>
    </Page>
  );
}

function VisitorTypeCard(props) {
  return (
    <Card
      sx={{
        p: 3,
        backgroundColor: "#F0F7FF",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <Avatar
        sx={{
          width: 80,
          height: 80,
          fontSize: 32,
          backgroundColor: "#D4E9FF",
          mx: "auto",
        }}
      >
        <Iconify sx={{ color: "#268EF2" }} icon={props.icon} />
      </Avatar>

      <Typography
        align="center"
        variant="subtitle2"
        component="div"
        color={"#268EF2"}
        sx={{ mt: 3 }}
      >
        {props.name}
      </Typography>
      <Typography
        align="center"
        variant="subtitle2"
        component="div"
        color={"#268EF2"}
      >
        {props.name2}
      </Typography>
    </Card>
  );
}
