import React, { useEffect, useState } from "react";

// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Snackbar,
  Alert as MuiAlert,
  Button,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// components
import Page from "../components/Page";
import MyAppbar from "../components/MyAppbar";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const initialValues = {
  otp: "",
};

const digitsOnly = (value) => /^\d+$/.test(value);

const otpLength = (value) => {
  if (!!value) {
    return value.length === 4;
  }
  return true;
};

const validationSchema = Yup.object({
  otp: Yup.string()
    .required("OTP is required")
    .test("Digits only", "This field should have digits only", digitsOnly)
    .test("len", "Must be 4 digits long", otpLength),
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OtpVerification() {
  const navigate = useNavigate();
  const [errOpen, setErrOpen] = useState(false); // Toast
  const [errMsg, setErrMsg] = useState(""); // Toast

  useEffect(() => {
    if (localStorage.getItem("contact") === null) {
      navigate("/phoneverification");
    }
  }, []);

  // Toast
  const handleErrClick = () => {
    setErrOpen(true);
  };

  // Toast
  const handleErrClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Toast
    setErrOpen(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      let contact = localStorage.getItem("contact");
      let visitorType = localStorage.getItem("visitorType");

      axios
        .post(
          `apiV1/gate/visitor-details/?contact=${contact}&type=${visitorType}`,
          values
        )
        .then(function (response) {
          localStorage.setItem("otp", values.otp);
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.getItem("visitorType") === "vendor"
            ? navigate("/vendorform")
            : console.log("Not a vendor");
          localStorage.getItem("visitorType") === "newAdmission"
            ? navigate("/newadmissionform")
            : console.log("Not a newAdmission");
          localStorage.getItem("visitorType") === "existingAdmission"
            ? navigate("/erpverification")
            : console.log("Not a existingAdmission");
        })
        .catch(function (err) {
          handleErrClick(); // Toast
          if (err.response.status == 400) {
            setErrMsg(err.response.data.error);
          } else {
            setErrMsg(err.response.status + " " + err.response.statusText);
          }

          err?.data?.error && console.log(err?.response?.data?.error);

          console.log(err.response);
          console.log("fail");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="OTP Verification form">
      <RootStyle sx={{ flexDirection: "column" }}>
        <Snackbar
          open={errOpen}
          autoHideDuration={6000}
          onClose={handleErrClose}
        >
          <Alert
            onClose={handleErrClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errMsg}
          </Alert>
        </Snackbar>

        <MyAppbar textOne="Verification" />

        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ flexGrow: 1 }} py={2} px={2} fullWidth>
              <Typography variant="h6">OTP Verification</Typography>
              <Typography variant="p">
                We sent a verification code to your phone number{" "}
                {localStorage.getItem("contact")}
              </Typography>
            </Box>
            <FormikProvider value={formik} validateOnChange>
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} margin={2}>
                  <TextField
                    fullWidth
                    label="OTP"
                    id="otp"
                    name="otp"
                    {...getFieldProps("otp")}
                    error={Boolean(touched.otp && errors.otp)}
                    helperText={touched.otp && errors.otp}
                    inputProps={{ inputMode: "numeric" }}
                  />

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    margin={4}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={14} /> : "Continue"}
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
