import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { getLeadDetailUrl } from "../../../../services/config";

import schoolImage from "../../../../assets/logo.svg";

import { useSearchParams } from "react-router-dom";

const initialValues = {
  contact: "",
  academicYear: "2022-23",
  institute: "",
};

const digitsOnly = (value) => /^\d+$/.test(value);

const phoneNumLength = (value) => {
  if (!!value) {
    return value.length === 10;
  }
  return true;
};

const validationSchema = Yup.object({
  contact: Yup.string()
    .required("Phone number is required")
    .test("Digits only", "This field should have digits only", digitsOnly)
    .test("len", "Must be 10 digits long", phoneNumLength),
});

function PhoneNumForm({ userData, setUserData, setActivePage }) {
  const [institutesList, setInstitutesList] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState("");

  const [branchId, setBranchId] = useState(""); // from url query

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    axios
      .get("apiV1/institute-detail/")
      .then((res) => {
        setInstitutesList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const paramId = searchParams.get("id");
    setBranchId(parseInt(paramId));
  }, []);

  useEffect(() => {
    setSelectedInstitute(
      institutesList.find((inst) => inst.crm_branch_id == branchId)
    );
    formik.setFieldValue(
      "institute",
      institutesList.find((inst) => inst.crm_branch_id == branchId)
    );
  }, [institutesList]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      localStorage.setItem("contact", values.contact);
      localStorage.setItem("academic_year", values.academicYear);
      localStorage.setItem("institute", JSON.stringify(values.institute));
      axios
        .get(`${getLeadDetailUrl()}?mobile_no=${values.contact}`, {
          headers: {
            "Access-Api-Key": "ntNPDkXA.KkRNanAflhbTBD4WowdaMMIE32dd5bveyH",
          },
        })
        .then((res) => {
          if (res.data?.status_code === 409) {
            console.log("No data found");
          } else {
            setUserData(res.data[0]);
            localStorage.setItem("userData", JSON.stringify(res.data[0]));
          }
          setActivePage((prevActivePage) => prevActivePage + 1);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema,
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <>
      <Box>
        <Box
          component="img"
          sx={{
            height: 160,
            width: 160,
            mx: "auto",
            my: 2,
          }}
          alt="school logo"
          src={
            selectedInstitute?.image ? selectedInstitute?.image : schoolImage
          }
        />

        {selectedInstitute?.name && (
          <Typography
            align="center"
            variant="h5"
            sx={{ color: "text.secondary" }}
          >
            {selectedInstitute?.name}
          </Typography>
        )}
        {selectedInstitute?.location && (
          <Typography
            align="center"
            variant="h6"
            sx={{ color: "text.secondary" }}
          >
            {selectedInstitute?.location}
          </Typography>
        )}
      </Box>

      <Box sx={{ flexGrow: 1 }} py={1} mt={1} fullWidth>
        <Typography variant="h6">Mobile Number</Typography>
        <Typography variant="p">
          Please enter your phone number so we can help you better.
        </Typography>
      </Box>

      <FormikProvider value={formik} validateOnChange>
        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
          <Stack spacing={3} marginY={2}>
            <TextField
              fullWidth
              label="Phone Number"
              id="contact"
              name="contact"
              {...getFieldProps("contact")}
              error={Boolean(touched.contact && errors.contact)}
              helperText={touched.contact && errors.contact}
              inputProps={{ inputMode: "numeric" }}
            />

            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Academic Year
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="2022-23"
                name="academicYear"
                row
                {...formik.getFieldProps("academicYear")}
                // error={Boolean(touched.academicYear && errors.academicYear)}
                // helperText={touched.academicYear && errors.academicYear}
              >
                <FormControlLabel
                  value="2022-23"
                  control={<Radio />}
                  label="2022-23"
                />
                <FormControlLabel
                  value="2023-24"
                  control={<Radio />}
                  label="2023-24"
                />
              </RadioGroup>
            </FormControl>

            {institutesList.filter((inst) => inst.crm_branch_id == branchId)
              .length > 0 ? null : (
              <Autocomplete
                fullWidth
                id="institute"
                name="institute"
                options={institutesList}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setSelectedInstitute(newValue);
                  setSearchParams({ id: parseInt(newValue?.crm_branch_id) });
                  formik.setFieldValue("institute", newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch " />
                )}
              />
            )}

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              margin={4}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={14} /> : "Continue"}
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}

export default PhoneNumForm;
