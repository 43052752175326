import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  Box,
  Button,
  TextField,
  Stack,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import MyAppbar from "../components/MyAppbar";
import axios from "axios";

import * as Yup from "yup";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

const initialValues = {
  erp_id: "",
};

const validationSchema = Yup.object({
  erp_id: Yup.string().required("Required"),
});

export default function EmployeeERP() {
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setUploading(true);

      localStorage.setItem("erp_id", values.erp_id);

      if (true) {
        navigate("/employeeform");
      } else {
        navigate("/waittime");
      }
    },
    validationSchema,
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <Page title="ERP Number">
      <Box sx={{ flexDirection: "column" }}>
        <RootStyle sx={{ flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
            <MyAppbar textOne="ERP Number" />
          </Box>
        </RootStyle>

        <RootStyle sx={{ flexDirection: "column" }}>
          <Container maxWidth="sm">
            <ContentStyle>
              <RootStyle sx={{ flexDirection: "column" }}>
                <Typography variant="h6">ERP Number</Typography>

                <Typography variant="p">Please enter the erp number</Typography>

                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3} marginY={2}>
                      <TextField
                        fullWidth
                        type="text"
                        label="ERP"
                        name="erp_id"
                        {...formik.getFieldProps("erp_id")}
                        error={Boolean(touched.erp_id && errors.erp_id)}
                        helperText={touched.erp_id && errors.erp_id}
                      />

                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        margin={4}
                        disabled={uploading}
                      >
                        {uploading ? (
                          <CircularProgress size={14} />
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </Stack>
                  </Form>
                </FormikProvider>
              </RootStyle>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Box>
    </Page>
  );
}
