import { styled } from "@mui/material/styles";
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Page from "../components/Page";
import schoolImage from "../assets/logo.svg";
import ScannerImg from "../assets/scanner.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

export default function WelcomeHome() {
  const navigate = useNavigate();

  const [branchList, setBranchList] = useState([]);
  const [branch, setBranch] = useState("");
  const [gateId, setGateId] = useState("");
  const [gateLoading, setGateLoading] = useState(false);

  useEffect(() => {
    fetchBranchList();
    localStorage.clear();
  }, []);

  function fetchBranchList() {
    axios
      .get(`apiV1/institute-detail/`)
      .then((res) => setBranchList(res?.data))
      .catch((err) => console.log(err));
  }

  function fetchGateId(branchId) {
    // return first gate id from the list of gates for a particular branch
    // set gateId with it
    setGateLoading(true);
    axios
      .get(`apiV1/institute-gates/?institute=${branchId}`)
      .then((res) => {
        if (res?.data[0]?.institute_gate[0]?.id) {
          setGateId(res?.data[0]?.institute_gate[0]?.id);
          localStorage.setItem("gateId", res?.data[0]?.institute_gate[0]?.id);
          navigate("/visitortype");
        } else {
          console.log("gate id not found!");
        }
      })
      .catch((err) => console.log(err));
  }

  function continueClickHandler() {
    fetchGateId(branch);
  }

  return (
    <Page title="Welcome">
      <RootStyle>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="sticky">
            <Toolbar
              style={{
                flexDirection: "row",
                alignSelf: "center",
                padding: "0.5em",
              }}
            >
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Welcome
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </RootStyle>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              component="img"
              sx={{
                height: 160,
                width: 160,
                mx: "auto",
              }}
              alt="school logo"
              src={schoolImage}
            />

            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", mb: 2 }}
            >
              Select Branch to continue
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Branch</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={branch}
                label="Branch"
                onChange={(e) => {
                  setBranch(e.target.value);
                  localStorage.setItem("instituteId", e.target.value);
                  localStorage.setItem(
                    "instituteName",
                    branchList.find((b) => b.id === e.target.value)?.name
                  );
                }}
              >
                {branchList.map((branch, index) => {
                  return (
                    <MenuItem value={branch?.id} key={branch?.id}>
                      {branch?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {branch && (
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={continueClickHandler}
                disabled={gateLoading}
              >
                {gateLoading ? "Loading..." : "Continue"}
              </Button>
            )}

            <Typography
              align="center"
              variant="subtitle2"
              sx={{
                mt: 2,
              }}
            >
              OR
            </Typography>

            {/* <Box
              component="img"
              sx={{
                height: 160,
                width: 160,
                mx: "auto",
                filter: "blur(3px)",
              }}
              alt="school logo"
              src={ScannerImg}
            /> */}

            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", mt: 2 }}
            >
              Scan the QR code at the School Gate
            </Typography>
            <Typography
              align="center"
              variant="subtitle"
              sx={{ color: "text.secondary", mb: 2 }}
            >
              to start the proccess
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
